import { FormRow, extendableComponent } from '@graphcommerce/next-ui'
import { i18n } from '@lingui/core'
import { Box, SxProps, Theme } from '@mui/material'
import { NavigationSearchBox } from '../Algolia/NavigationSearchBox'

type NavigationSearchProps = {
  sx?: SxProps<Theme>
}

export const navigationSearchRef = { current: null }

const componentName = 'NavigationSearch'
const parts = ['searchResults'] as const
const { classes } = extendableComponent(componentName, parts)

export function NavigationSearch(props: NavigationSearchProps) {
  const { sx = [] } = props

  return (
    <FormRow
      sx={[
        {
          position: 'relative',
          p: 0,
          width: { xs: '100%' },
          '&:not(:focus-within) .NavigationSearch-searchResults': {
            display: 'none',
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <NavigationSearchBox
        textFieldProps={{
          fullWidth: true,
          placeholder: i18n._(/* i18n */ 'What are you looking for?'),
        }}
        sx={(theme) => ({
          '& .MuiInputBase-root.MuiOutlinedInput-root': {
            backgroundColor: 'background.paper',
            typography: 'body1',
            whiteSpace: 'nowrap',
            borderRadius: theme.shape.borderRadius * 6,
            boxShadow: theme.shadows[3],

            '&:hover, &:focus, &:active': {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'text.secondary',
              },
            },
          },
          '& .MuiInputBase-input': {
            p: { xs: '8px 20px', md: '13px 20px', lg: `14px ${theme.spacings.sm}` },
            minWidth: { xs: '180px', lg: 'none' },
            [theme.breakpoints.down('md')]: {
              fontSize: '16px',

              '&::placeholder': {
                fontSize: theme.typography.body1.fontSize,
              },
            },
          },
          '& fieldset': { border: `2px solid ${theme.palette.primary.main}` },
          '& .MuiFormControl-root': { px: 0, textAlign: 'right' },
          '& use': { stroke: theme.palette.text.primary },
        })}
      />
      {/* Renders data from <AlgoliaSearchResults /> that's coming from pages/search/index.tsx */}
      <Box
        className={classes.searchResults}
        ref={navigationSearchRef}
        sx={{
          position: 'absolute',
          top: { xs: 'calc(100% + 10px)', md: 'calc(100% + 5px)' },
          width: '100%',
        }}
      />
    </FormRow>
  )
}
