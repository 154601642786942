import { FormAutoSubmit, TextFieldElement } from '@graphcommerce/ecommerce-ui'
import { SearchFormProps } from '@graphcommerce/magento-search'
import { SearchFormAdornment } from '@graphcommerce/magento-search/components/SearchForm/SearchFormAdornment'
import { extendableComponent } from '@graphcommerce/next-ui'
import { Box, useEventCallback, debounce } from '@mui/material'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { UseSearchBoxProps } from 'react-instantsearch-hooks-web'

type NavigationSearchBoxProps = UseSearchBoxProps & SearchFormProps

const { classes } = extendableComponent('NavigationSearchBox' as const, ['root'] as const)

export function NavigationSearchBox(props: NavigationSearchBoxProps) {
  const { urlHandle = 'search', textFieldProps, sx = [] } = props
  const searchInputElement = useRef<HTMLInputElement>(null)

  const router = useRouter()

  const isSearchPage = router.pathname.startsWith('/search')
  const searchValue = router.asPath.split('/')[2]?.replace('%20', ' ')
  const search = isSearchPage ? searchValue : ''

  const form = useForm({ defaultValues: { search } })
  const { handleSubmit, setValue, getValues, control } = form

  const callback = useEventCallback((formData: { search: string }) => {
    if (isSearchPage && !formData.search) return router.back()
    if (isSearchPage) return router.replace(`/${urlHandle}/${formData.search}`)
    if (!formData.search) return undefined
    return router.push(`/${urlHandle}/${formData.search}`)
  })

  const submit = handleSubmit(useMemo(() => debounce(callback, 500), [callback]))

  useEffect(() => {
    if (!isSearchPage) {
      setValue('search', '')
    } else if (!getValues('search') && searchValue) {
      setValue('search', searchValue)
    }
  }, [getValues, isSearchPage, searchValue, setValue])

  return (
    <Box className={classes.root} sx={sx}>
      <FormAutoSubmit control={form.control} submit={submit} />
      <TextFieldElement
        autoFocus={isSearchPage}
        variant='outlined'
        type='text'
        name='search'
        color='primary'
        control={control}
        onChange={submit}
        InputProps={{
          ...textFieldProps?.InputProps,
          endAdornment: (
            <SearchFormAdornment
              control={form.control}
              name='search'
              onReset={() => {
                setValue('search', '')
              }}
            />
          ),
        }}
        inputRef={searchInputElement}
        {...textFieldProps}
      />
    </Box>
  )
}
