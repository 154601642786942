import { CartFab } from '@graphcommerce/magento-cart'
import { magentoMenuToNavigation } from '@graphcommerce/magento-category'
import { CustomerFab, CustomerMenuFabItem } from '@graphcommerce/magento-customer'
import { WishlistFab, WishlistMenuFabItem } from '@graphcommerce/magento-wishlist'
import {
  DesktopNavActions,
  LayoutDefaultProps,
  iconHeart,
  NavigationFab,
  MenuFabSecondaryItem,
  IconSvg,
  NavigationProvider,
  NavigationOverlay,
  useNavigationSelection,
  useMemoDeep,
  useFabSize,
  iconCart,
  breakpointVal,
  NavigationNodeType,
} from '@graphcommerce/next-ui'
import iconLanguage from '@graphcommerce/next-ui/icons/language.svg'
import iconSmartphone from '@graphcommerce/next-ui/icons/smartphone.svg'
import iconSupport from '@graphcommerce/next-ui/icons/support.svg'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { Box, Divider, useTheme, Typography } from '@mui/material'
import { useRouter } from 'next/router'
import { useMemo } from 'react'
import { CustomerService } from '../GraphCMS/ContactInformation/CustomerService'
import { PhoneNumber } from '../GraphCMS/ContactInformation/PhoneNumber'
import { StoreMessage } from '../GraphCMS/StoreMessage/StoreMessage'
import { Navigation } from '../Navigation/Navigation'
import { NavigationSearch } from '../Navigation/NavigationSearch'
import { UspsHorizontalList } from '../Usps/UspsHorizontalList'
import { ChatFab } from './ChatFab'
import { Footer } from './Footer'
import { LayoutQuery } from './Layout.gql'
import { LayoutDefault } from './LayoutDefault'
import { Logo } from './Logo'
import { NavigationMenuFab } from './NavigationMenuFab'

export type LayoutNavigationProps = LayoutQuery &
  Omit<LayoutDefaultProps, 'footer' | 'header' | 'cartFab' | 'menuFab'>

export function LayoutNavigation(props: LayoutNavigationProps) {
  const {
    footer,
    menu,
    children,
    headerUsps,
    storeMessage,
    phoneNumber,
    defaultNavigationItems,
    scopedNavigationItems,
    ...uiProps
  } = props
  const selection = useNavigationSelection()
  const router = useRouter()
  const fabIconSize = useFabSize('responsive')
  const theme = useTheme()

  const menuItems = useMemo(
    () => [...(defaultNavigationItems?.items ?? []), ...(scopedNavigationItems?.[0]?.items ?? [])],
    [defaultNavigationItems, scopedNavigationItems],
  )

  return (
    <>
      <NavigationProvider
        selection={selection}
        serverRenderDepth={4}
        items={useMemoDeep(
          () => [
            { id: 'home', name: <Trans id='Home' />, href: '/' },
            {
              type: NavigationNodeType.BUTTON,
              id: 'products',
              name: <Trans id='Products' />,
              childItems: [...magentoMenuToNavigation(menu, false)],
            },
            ...(menuItems?.map((item) => ({
              ...{
                id: item.id,
                name: item.name,
                href: item.url ?? '',
              },
            })) || []),
            <Divider sx={{ my: theme.spacings.xs }} />,
            <MenuFabSecondaryItem
              key='telephone'
              icon={<IconSvg src={iconSmartphone} size='medium' />}
              href={phoneNumber ? phoneNumber.url : ''}
            >
              {phoneNumber?.description?.text}
            </MenuFabSecondaryItem>,
            <MenuFabSecondaryItem
              key='service'
              icon={<IconSvg src={iconSupport} size='medium' />}
              href='/service'
            >
              <Trans id='Customer Service' />
            </MenuFabSecondaryItem>,
            <CustomerMenuFabItem key='account' guestHref='/account/signin' authHref='/account'>
              <Trans id='Account' />
            </CustomerMenuFabItem>,
            <WishlistMenuFabItem
              onClick={() => selection.set(false)}
              key='wishlist'
              icon={<IconSvg src={iconHeart} size='medium' />}
            >
              <Trans id='Wishlist' />
            </WishlistMenuFabItem>,
            <MenuFabSecondaryItem
              key='webshops'
              icon={<IconSvg src={iconLanguage} size='medium' />}
              href='/webshops'
            >
              <Typography variant='body2'>
                10 <Trans id='Webshops' />
              </Typography>
            </MenuFabSecondaryItem>,
          ],
          [phoneNumber, selection, theme, menu, menuItems],
        )}
      >
        <NavigationOverlay
          stretchColumns={false}
          variantSm='left'
          sizeSm='full'
          justifySm='start'
          itemWidthSm='70vw'
          variantMd='left'
          sizeMd='full'
          justifyMd='start'
          itemWidthMd='230px'
          mouseEvent='hover'
          itemPadding='md'
          sx={{
            marginTop: '1px',
            '& > ul > li > a, & > ul > li > [role=button]': {
              '& span': {
                typography: 'h3',
              },
            },
            '& .NavigationItem-li.column1 > .MuiListItemButton-root > span': {
              ...breakpointVal('fontSize', 17, 19, theme.breakpoints.values),
            },
          }}
        />
      </NavigationProvider>
      <LayoutDefault
        {...uiProps}
        noSticky={router.asPath.split('?')[0] === '/'}
        beforeHeader={
          <>
            {storeMessage && <StoreMessage content={storeMessage} />}
            <UspsHorizontalList
              usps={headerUsps}
              sx={{ display: { xs: 'none', md: 'flex', '& .Scroller-root': { width: '100%' } } }}
            />
          </>
        }
        sx={{
          backgroundColor: 'background.paper',
          '& .LayoutDefault-fabs': {
            mt: storeMessage
              ? `calc((${theme.appShell.headerHeightMd} / 2) * -1 - calc(${fabIconSize} / 2))`
              : undefined,
          },
        }}
        header={
          <>
            <Box sx={{ display: 'flex', gap: { sm: 0, md: theme.spacings.sm } }}>
              <Logo />
              <NavigationMenuFab onClick={() => selection.set([])} size='small' />
            </Box>
            <Box
              sx={{
                [theme.breakpoints.down('md')]: {
                  '& svg': { color: 'white' },
                },
              }}
            >
              <NavigationSearch
                sx={{
                  '& .MuiInputBase-root.MuiOutlinedInput-root': {
                    width: { xs: '80vw', md: '100%', lg: '24vw' },
                    margin: 0,
                    borderRadius: '99em',
                    maxWidth: 500,
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 0,
                  },
                }}
              />
            </Box>
            <DesktopNavActions
              sx={{
                justifySelf: 'flex-end',
                '& .MuiFab-root svg': { color: 'white' },
                '& .MuiBadge-dot': {
                  backgroundColor: '#fcab10',
                },
                '& .CompareFab-root': {
                  position: 'absolute',
                  right: { xs: theme.spacings.xxs, md: theme.spacings.sm },
                  top: `calc(${theme.appShell.headerHeightMd} + ${theme.spacings.xxs})`,
                  height: 'auto',
                  color: theme.palette.text.primary,
                },
              }}
            >
              <PhoneNumber />
              <CustomerService
                pageLink='/service'
                description={i18n._(/* i18n */ 'Customer Service')}
              />
              <WishlistFab icon={<IconSvg src={iconHeart} size='large' />} />
              <CustomerFab sx={{ pr: 0 }} guestHref='/account/signin' authHref='/account' />
              <CartFab
                sx={{
                  '& .MuiButtonBase-root.MuiFab-circular, .CartFab-shadow': {
                    backgroundColor: 'unset !important',
                    boxShadow: 'unset',
                  },
                }}
                icon={<IconSvg src={iconCart} size='large' />}
              />
            </DesktopNavActions>
          </>
        }
        menuBar={<Navigation items={menuItems} selection={selection} />}
        footer={<Footer footer={footer} />}
        cartFab={<CartFab icon={<IconSvg src={iconCart} size='large' />} />}
        menuFab={
          <NavigationFab
            sx={{ '& button': { background: theme.palette.primary.main } }}
            onClick={() => selection.set([])}
          />
        }
      >
        {children}
        <ChatFab />
      </LayoutDefault>
    </>
  )
}
